import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { useI18next, I18nextContext } from "gatsby-plugin-react-i18next"
import {
  Box,
  MainPage,
  Text,
  FaqComponent,
  StartYoung,
  useMediaQuery,
} from "@youngagency/young-ui"
import { Container, Spacer } from "../components/styled"
import styled from "styled-components"
import {
  createArrayFromTranslationQuery,
  faqLinkParser,
  parseColor,
} from "../utils/helpers"
import HomeCardSection from "../components/home/homeCards.component"
import TestimonialLuca from "../images/testimonial-1.png"
import TestimonialJessica from "../images/testimonial-2.png"
import TestimonialAndrea from "../images/testimonial-3.png"
import TestimonialCardsSection from "../components/home/testimonialCards.component"
import StepIcon from "../images/step-icon.svg"
import Seo from "../components/seo"
import { createGAEvent } from "../utils"
import {
  FirstStepImages,
  HeroImages,
  LastStepImage,
  SecondStepImages,
  ThirdStepImages,
} from "../feature/home/components/LocalizedImage"

const UiContainer = styled(Container)`
  & > div:first-child {
    max-width: 950px !important;
  }
`

const CardsWrapperSection = styled.section`
  width: 100%;

  & .card-section-row {
    gap: 49px;
  }

  & .landing-card-section:last-child {
    background: var(--green);
    margin-top: 70px;
  }

  @media screen and (max-width: 1024px) {
    & .card-section-row {
      gap: 64px !important;

      h2 {
        font-size: 48px !important;
        line-height: 57px;
      }
    }
  }

  @media screen and (min-width: 40em) {
    & .card-section-row {
      gap: 117px;
    }
    & .landing-card-section:nth-child(2n) {
      .card-text-container {
        order: 2;
      }
      .card-img-container {
        order: 1;
      }
    }
  }
`

const LastCardContainer = styled(Container)`
  & > div > div > div > div > div {
    background: #ff298a !important;
  }

  & img:first-child {
    margin-bottom: 0px !important;
  }
`

const IndexPage = ({ pageContext: { i18n }, data }) => {
  const { t } = useI18next()
  const context = React.useContext(I18nextContext)
  const lng = context.language
  const { isMobile } = useMediaQuery()
  const homeImages = [
    <FirstStepImages />,
    <SecondStepImages />,
    <ThirdStepImages />,
    <LastStepImage />,
  ]
  const homeCardsArray = React.useMemo(
    () =>
      createArrayFromTranslationQuery(
        t,
        "home.homeCards.card",
        4,
        homeImages,
        "title",
        "subtitle",
        "img"
      ),
    []
  )

  const homeTestimonialCards = React.useMemo(
    () =>
      createArrayFromTranslationQuery(
        t,
        "home.testimonialCards.card",
        3,
        [TestimonialLuca, TestimonialJessica, TestimonialAndrea],
        "title",
        "caption",
        "img"
      ),
    [TestimonialLuca, TestimonialJessica, TestimonialAndrea]
  )

  const faqsTextArray = React.useMemo(() => {
    return createArrayFromTranslationQuery(
      t,
      "home.faqList.faq",
      3,
      [],
      "title",
      "content",
      "link"
    ).map(el => {
      return { ...el, content: faqLinkParser(el.content, el.link) }
    })
  }, [])

  const handleCardButtonClick = React.useCallback(() => {
    var userAgent =
      typeof window != "undefined" &&
      (navigator.userAgent || navigator.vendor || window.opera)

    if (typeof window != "undefined" && userAgent.match(/iPhone/i)) {
      window.open(`https://itunes.apple.com/it/app/stepdrop/id1446005022?mt=8`)
    } else if (typeof window != "undefined" && userAgent.match(/Android/i)) {
      window.open(
        `https://play.google.com/store/apps/details?id=com.youngplatform.young`
      )
    } else {
      window.open("https://youngplatform.page.link/XLkB")
    }
    createGAEvent("click_cta", "inizia_ora_step")()
  }, [])

  const handleClick = React.useCallback(e => {
    if (typeof window !== "undefined") {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: "action_exchange_app_download",
        content: "homepage",
      })
    }
  }, [])

  return (
    <Layout>
      <Seo
        originalPath={i18n.originalPath}
        seoIdentifier="/"
        lang={lng}
        title={t("home.seo.title")}
        description={t("home.seo.description")}
        hideSlogan
      />
      <Container size="fullwidth">
        <UiContainer pb="0px" pt={["0px", "48px"]} mb={["70px", "144px"]}>
          <MainPage
            fontSize="large"
            autoplay={true}
            style={{ padding: "0px", textAlign: "center" }}
            titleText={t("home.mainClaim")}
            subtitleText={t("home.secondClaim")}
            scrollElement={typeof window != "undefined" ? document : null}
            ecosystem
            isMobile={isMobile}
            step
            onClick={createGAEvent("click_cta", "download_step")}
          />
        </UiContainer>
        <Container>
          <Box
            maxWidth="949px"
            height={["auto", "542px"]}
            margin="auto"
            borderRadius="16px"
            overflow="hidden"
            style={{
              transform: "translateZ(0)",
            }}
          >
            <HeroImages />
          </Box>
        </Container>
        <Spacer height={["0px", "192px"]} />
        <CardsWrapperSection>
          {homeCardsArray.map((el, index) => {
            return <HomeCardSection {...el} key={index} />
          })}
        </CardsWrapperSection>
        <Spacer height={["90.5px", "193px"]} />
        <Container size={["lg", "md"]}>
          <Text type="display" fontSize={isMobile ? "xsmall" : "large"}>
            {parseColor(t("home.testimonialClaim"), "#FF298A")}
          </Text>
        </Container>
        <TestimonialCardsSection array={homeTestimonialCards} />
        {/* Start Faq Component */}
        <Container>
          <FaqComponent
            style={{
              paddingTop: "0px",
              paddingBottom: "0px",
              marginTop: "0px",
            }}
            title={t("home.faqTitle")}
            faqs={faqsTextArray}
            link={"https://youngplatform.com"}
            footer={t("home.faqFooterTitle", "#FF298A")}
          />
        </Container>
        {/* Start Big Card Component */}
        <LastCardContainer mt={["83px", "192px"]} size={["fullwidth", "lg"]}>
          <StartYoung
            width="460px"
            big="true"
            icon={StepIcon}
            titleText={
              isMobile
                ? t("home.lastCardClaim").replace(/\r?\n|\r/g, " ")
                : t("home.lastCardClaim")
            }
            buttonText={t("home.lastCardBtnClaim")}
            buttonIcon={"arrow_right"}
            textStyle={{ whiteSpace: "break-spaces" }}
            background={"#E81F76"}
            onButtonClick={handleCardButtonClick}
          />
        </LastCardContainer>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
export default IndexPage
